import React, {Fragment, useRef, useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import * as invoiceSubmissionSelectors from './invoiceSubmissionSelectors';
import * as invoiceSubmissionActionTypes from './invoiceSubmissionActionTypes';
// ui components
import invoiceSubmissionFiles from '../invoiceSubmissionFiles';
import {ButtonPrimary, ButtonSecondary, ErrorMessage, Input, RadioButton, RadioButtons, TextArea} from '../ui-components';
import Tooltip from '../ui-components/tooltip/Tooltip';
import InfoIcon from '../ui-components/assets/icons/info-red.svg';
// view elements
import MainServicesSection from './view-elements/MainServicesSection';
import RecoveryDurationSection from './view-elements/RecoveryDurationSection';
import AdditionalServicesSection from './view-elements/AdditionalServicesSection';
import EmptyTripReasonSection from './view-elements/EmptyTripReasonSection';
import InvoiceSubmissionDatesTimes from './view-elements/InvoiceSubmissionDatesTimes';
import TowingSection from './view-elements/TowingSection';
import PickUpSection from './view-elements/PickUpSection';
import MapSection from './view-elements/MapSection';
import FileUpload from '../file-upload/FileUpload';
// constants & enums
import {trailer} from './constants/trailer';
import {accident} from './constants/accident';
import {BillingTypes} from './constants/billingTypes';
import {eafMainServices, JourneyContinuation} from '../ella-dispo-entity-types';
import {apsPricingTypes} from './constants/apsPricingTypes';
import {formAnchors} from './constants/formAnchors';
import {AdditionalServices} from './constants/additionalServices';
// utils
import {assembleAddressString} from '../utils/address';
import {decodeAdditionalServices} from './utils/additionalServices';
import {calculateDuration} from './utils/times';
import {isMainServiceSelected} from './utils/mainServices';
import {scrollToAnchorWithOffset, setAnchorRefFromError} from '../utils/validationMessages';
import {getGeolocationFromString, setIsLocationInsidePolygon} from '../google-map/googleMapFunctions';
import * as mapSettings from '../google-map/googleMapSettings';
import {partnerSupportsFlatRate} from './utils/pricing';
import {isStartDateEqualEndDate} from '../utils/times';
import * as ISInitialStates from './utils/invoiceSubmissionInitialStates';
// style
import './FormView.scss';
import config from '../config';

const calculateRecoveryDuration = (recoveryStart, recoveryEnd, startOfRecovery, endOfRecovery) => {
    if (!recoveryStart.hours || !recoveryStart.minutes
        || !recoveryEnd.hours || !recoveryEnd.minutes) {
        return {recoveryDuration: '-'};
    }
    const startDateTime = startOfRecovery
        .hours(recoveryStart.hours || 0)
        .minutes(recoveryStart.minutes || 0);
    const endDateTime = endOfRecovery
        .hours(recoveryEnd.hours || 0)
        .minutes(recoveryEnd.minutes || 0);

    let diff = endDateTime.diff(startDateTime, 'minutes');
    if (diff < 0) {
        endDateTime.add(1, 'days');
        diff = endDateTime.diff(startDateTime, 'minutes');
    }
    if (diff > 24 * 60) {
        endDateTime.subtract(1, 'days');
        diff = endDateTime.diff(startDateTime, 'minutes');
    }
    const hours = Math.floor(diff / 60);
    const minutes = (diff % 60);
    const duration = hours || minutes ? `${hours ? `${hours} Std. ` : ''}${minutes ? `${minutes} Min.` : ''}` : '-';

    return {
        endOfRecovery: endDateTime,
        recoveryDuration: duration,
    };
};

const determineBillingType = (invoiceSubmission, partnerSupportsFlatRateFlag) => {
    const {damageLocation, vpPolygon, mainServices, decodedMainServices, billingType} = invoiceSubmission;
    const {startDate, endDate, startTime, endTime} = invoiceSubmission;

    const areStartAndEndDateTimeEqual = isStartDateEqualEndDate(startDate, endDate, startTime, endTime);
    const isServicePickup = mainServices.find(service => service.type === eafMainServices.PICKUP)
        || isMainServiceSelected(decodedMainServices, eafMainServices.PICKUP);
    const isDamageLocationInsidePolygon = damageLocation.lat && damageLocation.lng && vpPolygon
        ? setIsLocationInsidePolygon({
            lat: damageLocation.lat,
            lng: damageLocation.lng,
            partnerSupportsFlatRateFlag,
            polygon: vpPolygon,
        })
        : false;
    const isRecoveryService = isMainServiceSelected(decodedMainServices, eafMainServices.RECOVERY);
    const isRoadsideAssistanceService = isMainServiceSelected(decodedMainServices, eafMainServices.ROADSIDE_ASSISTANCE);

    if (partnerSupportsFlatRateFlag
        && isDamageLocationInsidePolygon
        && !isRecoveryService
        && ((areStartAndEndDateTimeEqual && isServicePickup)
            || isRoadsideAssistanceService)
    ) {
        return BillingTypes.FIXED_PRICE;
    }

    if (!partnerSupportsFlatRateFlag || isRecoveryService) return BillingTypes.TIME;

    return billingType;
};

const InvoiceSubmissionFormView = props => {
    const {translate} = useTranslate();
    const {invoiceSubmission, errors, pricingInfo, invoiceSubmissionMainServices} = props;
    const {isInvoiceSubmissionSubmitted, isInvoiceSubmissionRequestedSubService, updateInvoiceSubmission} = props;
    const {fetchServiceFixedPricesForSelectedDate, formAnchor, isInvoiceSubmissionStatusRequest} = props;
    const {uploadFile, deleteFile, files} = props;
    const formStartRef = useRef();
    const mainServicesSectionRef = useRef();
    const recoverySectionRef = useRef();
    const towingSectionRef = useRef();
    const pickUpSectionRef = useRef();
    const additionalServicesSectionRef = useRef();
    const isComponentMounted = useRef(false);

    const partnerSupportsFlatRateFlag = partnerSupportsFlatRate(pricingInfo);

    const [invoiceSubmissionFormData, setInvoiceSubmissionFormData] = useState();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const isEditingDisabled = isInvoiceSubmissionRequestedSubService
        || (isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest)
        || !invoiceSubmissionFormData?.invoiceSubmissionId.endsWith('A1');

    const journeyContinuationWrapperClass = classnames('ace-error__wrapper', {
        'ace-error__wrapper--errors': errors['assignment.journeyContinuation'],
    });

    const scrollToAnchor = useCallback(() => {
        if (formAnchor) {
            formAnchor === formAnchors.FIRST_FORM
                ? scrollToAnchorWithOffset(formStartRef)
                : (formAnchor === formAnchors.SECOND_FORM
                    ? scrollToAnchorWithOffset(mainServicesSectionRef)
                    : scrollToAnchorWithOffset(additionalServicesSectionRef));
        }
    }, [formAnchor]);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const decodedRef = setAnchorRefFromError(errors);
            let element;
            switch (decodedRef) {
                case 'formStartRef': {
                    element = formStartRef;
                    break;
                }
                case 'mainServicesSectionRef': {
                    element = mainServicesSectionRef;
                    break;
                }
                case 'recoverySectionRef': {
                    element = recoverySectionRef;
                    break;
                }
                case 'towingSectionRef': {
                    element = towingSectionRef;
                    break;
                }
                case 'pickUpSectionRef': {
                    element = pickUpSectionRef;
                    break;
                }
                case 'additionalServicesSectionRef': {
                    element = additionalServicesSectionRef;
                    break;
                }
                default:
                    break;
            }
            if (element) {
                scrollToAnchorWithOffset(element);
            }
        }
    }, [errors]);

    useEffect(() => {
        if (!isComponentMounted.current && !isFormSubmitted) {
            isComponentMounted.current = true;

            const {invoiceSubmissionAddressString, vpPolygon} = invoiceSubmission;
            const {decodedMainServices, towingDestinationAddress} = invoiceSubmission;
            const geocoder = new window.google.maps.Geocoder();
            const addressString = invoiceSubmissionAddressString
                || assembleAddressString({
                    street: invoiceSubmission.damageAddressStreet,
                    postalCode: invoiceSubmission.damagePostCode,
                    city: invoiceSubmission.damageCity,
                    country: invoiceSubmission.damageCountry,
                });

            let newInvoiceData = {
                ...invoiceSubmission,
                billingType: invoiceSubmission.billingType
                    ? invoiceSubmission.billingType
                    : determineBillingType(
                        invoiceSubmission,
                        partnerSupportsFlatRateFlag,
                    ),
                invoiceSubmissionAddressString: assembleAddressString({
                    street: invoiceSubmission.damageAddressStreet,
                    postalCode: invoiceSubmission.damagePostCode,
                    city: invoiceSubmission.damageCity,
                    country: invoiceSubmission.damageCountry,
                }),
                towingDestinationAddress: assembleAddressString({
                    street: invoiceSubmission.towingAddressStreet,
                    postalCode: invoiceSubmission.towingAddressPostCode,
                    city: invoiceSubmission.towingAddressCity,
                    country: invoiceSubmission.towingAddressCountry,
                }),
                pickUpDestinationAddress: assembleAddressString({
                    street: invoiceSubmission.pickUpAddressStreet,
                    postalCode: invoiceSubmission.pickUpAddressPostCode,
                    city: invoiceSubmission.pickUpAddressCity,
                    country: invoiceSubmission.pickUpAddressCountry,
                }),
                towingDestinationType: invoiceSubmission.towingDestinationType,
                pickUpDestinationType: invoiceSubmission.pickUpDestinationType,
                additionalServices: decodeAdditionalServices(invoiceSubmission.additionalServices),
                invoiceSubmissionDuration: calculateDuration({
                    startDate: invoiceSubmission.startDate,
                    startTime: invoiceSubmission.startTime,
                    endDate: invoiceSubmission.endDate,
                    endTime: invoiceSubmission.endTime,
                }),
            };

            getGeolocationFromString(addressString, geocoder)
                .then(geolocation => {
                    const {location, address} = geolocation;
                    let damageLocationData = {};
                    if (address) {
                        damageLocationData = {
                            damageLocation: location,
                            isInvoiceSubmissionInsideVpPolygon: setIsLocationInsidePolygon({
                                lat: location.lat,
                                lng: location.lng,
                                partnerSupportsFlatRateFlag,
                                polygon: vpPolygon,
                            }),
                        };
                    }
                    if (!address) {
                        damageLocationData = {
                            damageLocation: {
                                lat: mapSettings.DEFAULT_MAP_CENTER.lat,
                                lng: mapSettings.DEFAULT_MAP_CENTER.lng,
                            },
                        };
                    }
                    setInvoiceSubmissionFormData(prevState => ({
                        ...prevState,
                        ...newInvoiceData,
                        ...damageLocationData,
                        billingType: invoiceSubmission.billingType
                            ? invoiceSubmission.billingType
                            : determineBillingType(
                                {...invoiceSubmission, ...newInvoiceData, ...damageLocationData},
                                partnerSupportsFlatRateFlag,
                            ),
                    }));
                });
            if (isMainServiceSelected(decodedMainServices, eafMainServices.TOWING)
                && towingDestinationAddress) {
                getGeolocationFromString(towingDestinationAddress, geocoder)
                    .then(geolocation => {
                        if (geolocation) {
                            const {address, location} = geolocation;
                            if (address) {
                                const {lat, lng} = location;
                                const isTowingLocationInsideVpPolygon = setIsLocationInsidePolygon({
                                    lat,
                                    lng,
                                    partnerSupportsFlatRateFlag,
                                    polygon: vpPolygon,
                                });
                                newInvoiceData = {
                                    ...newInvoiceData,
                                    towingLocation: {
                                        lat,
                                        lng,
                                    },
                                    isTowingLocationInsideVpPolygon,
                                };
                                setInvoiceSubmissionFormData(prevState => ({
                                    ...prevState,
                                    ...newInvoiceData,
                                }));
                            }
                        }
                    });
            }
            setInvoiceSubmissionFormData({
                ...newInvoiceData,
            });

            scrollToAnchor();
        }
    }, [scrollToAnchor, invoiceSubmission, partnerSupportsFlatRateFlag, isFormSubmitted]);

    // todo merge into one, switch by field name;
    const onFormChange = data => {
        setInvoiceSubmissionFormData({
            ...invoiceSubmissionFormData,
            ...data,
        });
    };

    const onFormFieldChange = (name, value) => {
        setInvoiceSubmissionFormData({
            ...invoiceSubmissionFormData,
            [name]: value,
            ...(name === 'hasTrailer' && value === trailer.HAS_TRAILER && {
                additionalServices: invoiceSubmissionFormData?.additionalServices.filter(additionalService => (
                    additionalService.type !== AdditionalServices.SURCHARGE_TRAILER
                )),
            }),
        });
    };

    const onDateTimeChange = (name, value) => {
        let fixedPriceParams = null;

        if (name === 'endDate' && value && !moment(invoiceSubmissionFormData?.endDate, 'DD.MM.YYYY').isSame(
            moment(value, 'DD.MM.YYY'),
        )) {
            const date = moment(value, 'DD.MM.YYYY');
            if (date.isValid() && date.year().toString().length === 4) {
                fixedPriceParams = {
                    serviceEndDateTime: date,
                    invoiceSubmissionId: invoiceSubmissionFormData.invoiceSubmissionId,
                    type: apsPricingTypes.CONTRACT_PARTNER_FIXED_PRICE,
                };
            }
        }
        // calculate duration
        const {startDate, endDate, startTime, endTime, journeyContinuation} = invoiceSubmissionFormData;
        const invoiceSubmissionDuration = calculateDuration({
            startDate: name === 'startDate' ? value : startDate,
            startTime: name === 'startTime' ? value : startTime,
            endDate: name === 'endDate' ? value : endDate,
            endTime: name === 'endTime' ? value : endTime,
        });
        const shouldUpdateBillingType = name === 'startDate'
            && journeyContinuation === JourneyContinuation.EMPTY_TRIP
            && moment(value, 'DD.MM.YYYY').isSameOrAfter(config.INTERSECTION_DATE);

        // store data
        setInvoiceSubmissionFormData({
            ...invoiceSubmissionFormData,
            invoiceSubmissionDuration,
            [name]: value,
            ...(shouldUpdateBillingType && {billingType: BillingTypes.TIME}),
        });

        // call price fetch
        if (fixedPriceParams) {
            fetchServiceFixedPricesForSelectedDate(fixedPriceParams);
        }
    };

    const onJourneyContinuationChange = journeyContinuation => {
        setInvoiceSubmissionFormData({
            ...invoiceSubmissionFormData,
            journeyContinuation,
            decodedMainServices: journeyContinuation === JourneyContinuation.EMPTY_TRIP
                ? {[JourneyContinuation.EMPTY_TRIP]: JourneyContinuation.EMPTY_TRIP}
                : {},
            billingType: journeyContinuation === JourneyContinuation.EMPTY_TRIP
            && moment(invoiceSubmissionFormData.startDate, 'DD.MM.YYYY').isSameOrAfter(config.INTERSECTION_DATE)
                ? BillingTypes.TIME
                : invoiceSubmissionFormData.billingType,
            ...ISInitialStates.journeyContinuationInitialState,
        });
    };

    const onBillingTypeChange = billingType => {
        const {additionalServices} = invoiceSubmissionFormData;
        if (billingType === BillingTypes.FIXED_PRICE && Object.keys(additionalServices).length > 0) {
            const newAdditionalServices = additionalServices.filter(additionalService => (
                additionalService.additionalServiceType !== 'ADDITIONAL_STAFF'
            ));

            setInvoiceSubmissionFormData({
                ...invoiceSubmissionFormData,
                billingType,
                additionalServices: newAdditionalServices,
                ...(!newAdditionalServices.length && {additionalServicesComment: ''}),
            });
            return;
        }
        setInvoiceSubmissionFormData({
            ...invoiceSubmissionFormData,
            billingType,
        });
    };

    const onMainServiceDataChange = mainServiceData => {
        const {decodedMainServices, additionalServices, additionalComment} = invoiceSubmissionFormData;

        const hasTowing = isMainServiceSelected(decodedMainServices, eafMainServices.TOWING);
        const isTowingSelected = isMainServiceSelected(mainServiceData.decodedMainServices, eafMainServices.TOWING);
        const hasRecovery = isMainServiceSelected(decodedMainServices, eafMainServices.RECOVERY);
        const isRecoverySelected = isMainServiceSelected(mainServiceData.decodedMainServices, eafMainServices.RECOVERY);
        const hasPickup = isMainServiceSelected(decodedMainServices, eafMainServices.PICKUP);
        const isPickupSelected = isMainServiceSelected(mainServiceData.decodedMainServices, eafMainServices.PICKUP);
        const isRoadsideAssistanceSelected = isMainServiceSelected(
            mainServiceData.decodedMainServices,
            eafMainServices.ROADSIDE_ASSISTANCE,
        );

        const newMainServiceData = {
            ...mainServiceData,
            ...(Object.keys(mainServiceData.decodedMainServices).length === 0
                ? {
                    additionalServices: [],
                    additionalServicesComment: '',
                }
                : []
            ),
            ...(additionalServices.find(additionalService => (
                additionalService.type === AdditionalServices.SURCHARGE_TONNAGE_ROADSIDE_ASSISTANCE
            ))
                && !isRoadsideAssistanceSelected && {
                additionalServices: additionalServices.filter(additionalService => (
                    additionalService.type !== AdditionalServices.SURCHARGE_TONNAGE_ROADSIDE_ASSISTANCE
                )),
                additionalComment: additionalServices.filter(additionalService => (
                    additionalService.type !== AdditionalServices.SURCHARGE_TONNAGE_ROADSIDE_ASSISTANCE
                )).length === 0
                    ? ''
                    : additionalComment,
            }),
            ...(hasTowing && !isTowingSelected ? ISInitialStates.towingInitialState : []),
            ...(hasRecovery && !isRecoverySelected ? ISInitialStates.recoveryInitialState : []),
            ...(!hasRecovery && isRecoverySelected ? {billingType: BillingTypes.TIME} : []),
            ...(hasPickup && !isPickupSelected ? ISInitialStates.pickupInitialState : []),
            ...(!hasPickup && isPickupSelected ? {billingType: BillingTypes.TIME} : []),
        };

        setInvoiceSubmissionFormData({
            ...invoiceSubmissionFormData,
            ...newMainServiceData,
        });
    };

    const onTowingDataChange = towingData => {
        const {decodedMainServices, additionalServices, billingType} = invoiceSubmissionFormData;
        const {isTowingLocationInsideVpPolygon} = invoiceSubmissionFormData;

        setInvoiceSubmissionFormData({
            ...invoiceSubmissionFormData,
            ...towingData,
            ...(towingData.isTowingLocationInsideVpPolygon !== undefined
                && towingData.isTowingLocationInsideVpPolygon !== isTowingLocationInsideVpPolygon && {
                billingType: billingType || ((towingData.isTowingLocationInsideVpPolygon || !towingData.towingLocation)
                        && !isMainServiceSelected(decodedMainServices, eafMainServices.RECOVERY)
                        && !additionalServices
                            .find(({type}) => {
                                return AdditionalServices[type] === AdditionalServices.ADDITIONAL_STAFF;
                            })
                    ? BillingTypes.FIXED_PRICE : BillingTypes.TIME),
            }),
        });
    };

    const onRecoveryTimeChange = (name, value) => {
        const {recoveryStart, recoveryEnd, startOfRecovery, endOfRecovery} = invoiceSubmissionFormData;
        const recoveryDurationData = calculateRecoveryDuration(
            name === 'recoveryStart' ? value : recoveryStart,
            name === 'recoveryEnd' ? value : recoveryEnd,
            name === 'startOfRecovery' ? value : startOfRecovery,
            name === 'endOfRecovery' ? value : endOfRecovery,
        );
        setInvoiceSubmissionFormData({
            ...invoiceSubmissionFormData,
            [name]: value,
            ...recoveryDurationData,
        });
    };

    const onSubmitForm = hasValidation => {
        const {billingType, decodedMainServices, assignmentId, invoiceSubmissionId} = invoiceSubmissionFormData;
        const {startDate, endDate, startTime, endTime, mainServices} = invoiceSubmissionFormData;

        const {emptyEndTimeForPickUp} = billingType === BillingTypes.TIME
        && isMainServiceSelected(decodedMainServices, eafMainServices.PICKUP)
        && endTime.hours === ''
        && endTime.minutes === '';

        if (emptyEndTimeForPickUp) {
            setInvoiceSubmissionFormData({
                ...invoiceSubmissionFormData,
                endTime: invoiceSubmissionFormData.startTime,
            });
        }

        const isEqualTimeForPickUpService = isStartDateEqualEndDate(
            startDate,
            endDate,
            startTime,
            emptyEndTimeForPickUp ? startTime : endTime,
        )
            && (mainServices.find(service => service.type === eafMainServices.PICKUP)
            || isMainServiceSelected(decodedMainServices, eafMainServices.PICKUP)
            );
        setIsFormSubmitted(true);
        updateInvoiceSubmission({
            assignmentId,
            invoiceSubmissionId,
            data: {
                ...invoiceSubmissionFormData,
                endTime: emptyEndTimeForPickUp ? startTime : endTime,
                ...(!!isEqualTimeForPickUpService && {billingType: BillingTypes.TIME}),
            },
            isNoValidation: !hasValidation,
        });
    };

    if (!invoiceSubmissionFormData) return null;

    const isEmptyTrip = invoiceSubmissionFormData.journeyContinuation === JourneyContinuation.EMPTY_TRIP;
    const isTowingSelected = isMainServiceSelected(
        invoiceSubmissionFormData.decodedMainServices,
        eafMainServices.TOWING,
    );
    const isPickup = isMainServiceSelected(invoiceSubmissionFormData.decodedMainServices, eafMainServices.PICKUP);
    const isRecovery = isMainServiceSelected(invoiceSubmissionFormData.decodedMainServices, eafMainServices.RECOVERY);
    const isMainCommentMandatory = isTowingSelected
        ? invoiceSubmissionFormData.billingType === BillingTypes.TIME
            && invoiceSubmissionFormData.isInvoiceSubmissionInsideVpPolygon
            && invoiceSubmissionFormData.isTowingLocationInsideVpPolygon
        : invoiceSubmissionFormData.isInvoiceSubmissionInsideVpPolygon
            && invoiceSubmissionFormData.billingType === BillingTypes.TIME;
    const isFixedPriceDisabled = !partnerSupportsFlatRateFlag
        || (isPickup && isStartDateEqualEndDate(
            invoiceSubmissionFormData.startDate,
            invoiceSubmissionFormData.endDate,
            invoiceSubmissionFormData.startTime,
            invoiceSubmissionFormData.endTime,
        )) || ((isEmptyTrip || invoiceSubmission?.isForeignInsurance)
            && moment(invoiceSubmissionFormData.startDate, 'DD.MM.YYYY').isSameOrAfter(config.INTERSECTION_DATE))
            || isRecovery;

    return (
        <>
            <div className="ace-bottom-margin--xl" ref={formStartRef}>
                <div className="ace-grid__row ace-bottom-margin--lg">
                    <div className="col-xs--12">
                        <h4 className="ace-h4">{translate('form_view.header.title')}</h4>
                    </div>
                </div>
                <InvoiceSubmissionDatesTimes
                    invoiceSubmission={invoiceSubmissionFormData}
                    onDataChange={onDateTimeChange}
                    errors={errors}
                    isDisabled={isEditingDisabled}
                />
                <MapSection
                    invoiceSubmission={invoiceSubmissionFormData}
                    pricingInfo={pricingInfo}
                    onDataChange={onFormChange}
                    errors={errors}
                    isDisabled={isEditingDisabled}
                />
                <div className="ace-grid__row">
                    <div className="col-xs--12 ace-bottom-margin--sm">
                        <p className="ace-copy-m">{translate('form_view.text.journey_continuation')}</p>
                    </div>
                </div>
                <div className={classnames('ace-grid__row', journeyContinuationWrapperClass)}>
                    <RadioButtons
                        className={errors['assignment.journeyContinuation'] ? '' : 'ace-bottom-margin--md'}
                        onChange={onJourneyContinuationChange}
                    >
                        {Object.keys(JourneyContinuation).map(option => {
                            return (
                                <RadioButton
                                    key={option}
                                    id={option}
                                    selectedId={invoiceSubmissionFormData.journeyContinuation}
                                    className="col-xs--3"
                                >
                                    {translate(`global.journey_continuation.${option.toLowerCase()}`)}
                                </RadioButton>
                            );
                        })}
                    </RadioButtons>
                </div>
                {errors['assignment.journeyContinuation'] && (
                <div className="ace-grid__row">
                    <div className="col-xs--12 ace-bottom-margin--sm">
                        <ErrorMessage error={translate(`global.validation_messages.${errors['assignment.journeyContinuation']}`)} />
                    </div>
                </div>
                )}
                <div className="ace-grid__row">
                    <div className="col-xs--12 ace-bottom-margin--sm">
                        <p className="ace-copy-m">{translate('form_view.text.billing_after')}</p>
                    </div>
                </div>
                <RadioButtons
                    className="ace-bottom-margin--md"
                    onChange={onBillingTypeChange}
                    error={errors['assignment.billingType'] && translate(`global.validation_messages.${errors['assignment.billingType']}`)}
                >
                    {Object.keys(BillingTypes).map(option => {
                        return (
                            <RadioButton
                                key={option}
                                id={option}
                                selectedId={invoiceSubmissionFormData.billingType}
                                className="col-xs--3"
                                isDisabled={isEditingDisabled
                                || (isFixedPriceDisabled && option === BillingTypes.FIXED_PRICE)}
                            >
                                {translate(`global.billing_type.${option.toLowerCase()}`)}
                            </RadioButton>
                        );
                    })}
                </RadioButtons>
                <div className="edit-page__form-container" ref={mainServicesSectionRef}>
                    <div className="ace-grid__row ace-bottom-margin--lg">
                        <div className="col-xs--12">
                            <h4 className="ace-h4">{translate('form_view.header.performance_data')}</h4>
                        </div>
                    </div>
                    <div className="ace-grid__row ace-bottom-margin--lg">
                        <div className="col-xs--12 col-lg--6">
                            {invoiceSubmissionFormData.journeyContinuation !== JourneyContinuation.EMPTY_TRIP ? (
                                <MainServicesSection
                                    invoiceSubmission={invoiceSubmissionFormData}
                                    onMainServicesChange={onMainServiceDataChange}
                                    errors={errors}
                                    isDisabled={isEditingDisabled}
                                />
                            ) : (
                                <EmptyTripReasonSection
                                    invoiceSubmission={invoiceSubmissionFormData}
                                    onEmptyTripReasonChange={value => {
                                        onFormFieldChange('emptyTripReason', value);
                                    }}
                                    errors={errors}
                                />
                            )}
                        </div>
                        <div className="col-xs--10 col-lg--6">
                            <div className="service-form__text-area-wrapper">
                                <TextArea
                                    className="service-form__text-area-container"
                                    value={invoiceSubmissionFormData.mainServicesComment}
                                    onChange={value => { onFormFieldChange('mainServicesComment', value); }}
                                    name="mainServicesComment"
                                    label={translate('form_view.text_area_label.comment')}
                                    isOptional={!isMainCommentMandatory}
                                    isDisabled={isEditingDisabled}
                                    error={errors.mainServicesComment && translate(`global.validation_messages.${errors.mainServicesComment}`)}
                                />
                            </div>
                        </div>
                    </div>
                    {isMainServiceSelected(invoiceSubmissionFormData.decodedMainServices, eafMainServices.RECOVERY) && (
                        <div
                            ref={recoverySectionRef}
                        >
                            <div className="ace-grid__row">
                                <RecoveryDurationSection
                                    invoiceSubmission={invoiceSubmissionFormData}
                                    onRecoveryTimeChange={onRecoveryTimeChange}
                                    errors={errors}
                                />
                            </div>
                        </div>
                    )}
                    {isMainServiceSelected(invoiceSubmissionFormData.decodedMainServices, eafMainServices.TOWING)
                         && !isEmptyTrip && (
                         <TowingSection
                             invoiceSubmission={invoiceSubmissionFormData}
                             onDataChange={onTowingDataChange}
                             pricingInfo={pricingInfo}
                             errors={errors}
                             isDisabled={isEditingDisabled}
                             forwardRef={towingSectionRef}
                         />
                    )}
                    {isMainServiceSelected(invoiceSubmissionFormData.decodedMainServices, eafMainServices.PICKUP)
                         && !isEmptyTrip && (
                         <PickUpSection
                             invoiceSubmission={invoiceSubmissionFormData}
                             onDataChange={onFormChange}
                             forwardRef={pickUpSectionRef}
                             errors={errors}
                             isDisabled={isEditingDisabled}
                         />
                    )}
                    <div className="ace-grid__row ace-bottom-margin--md">
                        <div className="col-xs--12">
                            <span className="ace-copy-m">{translate('form_view.text.third_party_involved')}</span>
                        </div>
                    </div>
                    <RadioButtons
                        className="ace-bottom-margin--md"
                        onChange={value => { onFormFieldChange('isAccident', value); }}
                    >
                        {Object.keys(accident).map(option => {
                            return (
                                <RadioButton
                                    key={option}
                                    id={option}
                                    selectedId={invoiceSubmissionFormData.isAccident}
                                    className="col-xs--3 col-xl--2"
                                    isDisabled={isEditingDisabled}
                                >
                                    {translate(`global.accident.${option.toLowerCase()}`)}
                                </RadioButton>
                            );
                        })}
                    </RadioButtons>
                    {!isEmptyTrip && (
                        <Fragment>
                            <div className="ace-grid__row ace-bottom-margin--md">
                                <div className="col-xs--12">
                                    <span
                                        className="ace-copy-m"
                                    >{translate('form_view.text.has_trailer')}
                                    </span>
                                </div>
                            </div>
                            <RadioButtons
                                className="ace-bottom-margin--md"
                                onChange={value => { onFormFieldChange('hasTrailer', value); }}
                            >
                                {Object.keys(trailer).map(option => {
                                    return (
                                        <RadioButton
                                            key={option}
                                            id={option}
                                            selectedId={invoiceSubmissionFormData.hasTrailer}
                                            className="col-xs--3 col-xl--2"
                                            isDisabled={isEditingDisabled}
                                        >
                                            {translate(`global.trailer.${option.toLowerCase()}`)}
                                        </RadioButton>
                                    );
                                })}
                            </RadioButtons>
                        </Fragment>
                    )}
                    <AdditionalServicesSection
                        invoiceSubmission={invoiceSubmissionFormData}
                        invoiceSubmissionMainServices={invoiceSubmissionMainServices}
                        onDataChange={onFormChange}
                        isInvoiceSubmissionRequestedSubService={isInvoiceSubmissionRequestedSubService}
                        isInvoiceSubmissionClosed={false}
                        forwardRef={additionalServicesSectionRef}
                        errors={errors}
                    />
                    {isInvoiceSubmissionSubmitted && !isInvoiceSubmissionStatusRequest && (
                        <div className="ace-grid__row ace-bottom-margin--md">
                            <div className="col-xs--12">
                                <div className="internal-invoice__wrapper">
                                    <span className="ace-copy-m">{translate('form_view.text.internal_invoice_number')}</span>
                                    <Tooltip
                                        message={translate('form_view.tooltip_message.internal_invoice_number')}
                                        position="left"
                                    >
                                        <img
                                            className="overview-price__internal-invoice-icon"
                                            src={InfoIcon}
                                            alt=""
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-xs--4">
                                <Input
                                    label={translate('form_view.input_label.external_document_number')}
                                    value={invoiceSubmissionFormData.externalDocumentNo}
                                    onChange={data => {
                                        onFormFieldChange('externalDocumentNo', data.value);
                                    }}
                                    additionalAttributes={{maxLength: '35'}}
                                    error={errors.externalDocumentNumber
                                        && translate(`global.validation_messages.${errors.externalDocumentNumber}`)}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className="ace-grid__row ace-bottom-margin--sm">
                    <div className="col-xs--12">
                        <span className="ace-copy-m">{translate('form_view.text.add_attachments')}</span>
                    </div>
                </div>
                <FileUpload
                    isInvoiceSubmissionClosed={isEditingDisabled}
                    error={errors.attachments && translate(`global.validation_messages.${errors.attachments}`)}
                    uploadFile={uploadFile}
                    deleteFile={deleteFile}
                    files={{...Object.values(files).filter(file => !file.isSignature)}}
                />
            </div>
            {!isInvoiceSubmissionSubmitted || isInvoiceSubmissionStatusRequest ? (
                <div className="ace-grid__row ace-bottom-margin--lg">
                    <div className="col-start-xs--3 col-xs--4 col-start-xl--4 col-xl--3">
                        <ButtonSecondary
                            label={translate('form_view.button_label.save')}
                            name="btnSave"
                            onClick={() => { onSubmitForm(false); }}
                        />
                    </div>
                    <div className="col-xs--4 col-xl--3">
                        <ButtonPrimary
                            label={translate('form_view.button_label.proceed')}
                            name="btnProceed"
                            onClick={() => { onSubmitForm(true); }}
                        />
                    </div>
                </div>
            ) : (
                <div className="ace-grid__row ace-bottom-margin--lg">
                    <div className="col-start-xs--4 col-xs--5">
                        <ButtonPrimary
                            label={translate('form_view.button_label.submit')}
                            name="btnSubmit"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

InvoiceSubmissionFormView.propTypes = {
    errors: PropTypes.object,
    invoiceSubmission: PropTypes.object,
    pricingInfo: PropTypes.number,
    isInvoiceSubmissionSubmitted: PropTypes.bool,
    isInvoiceSubmissionRequestedSubService: PropTypes.bool,
    isInvoiceSubmissionStatusRequest: PropTypes.bool,
    uploadFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    fetchServiceFixedPricesForSelectedDate: PropTypes.func.isRequired,
    updateInvoiceSubmission: PropTypes.func.isRequired,
    invoiceSubmissionMainServices: PropTypes.object,
    files: PropTypes.object,
    formAnchor: PropTypes.string,
};

InvoiceSubmissionFormView.defaultProps = {
    errors: {},
    invoiceSubmission: {},
    invoiceSubmissionMainServices: {},
    pricingInfo: null,
    isInvoiceSubmissionSubmitted: false,
    isInvoiceSubmissionRequestedSubService: false,
    isInvoiceSubmissionStatusRequest: false,
    files: null,
    formAnchor: '',
};

const mapStateToProps = (state, props) => {
    const getInvoiceSubmission = invoiceSubmissionSelectors.createInvoiceSubmissionSelector();

    return {
        files: state.invoiceSubmissionFilesReducer.files,
        invoiceSubmissionMainServices: state.invoiceSubmission.followUpInvoiceSubmissionMainServices,
        invoiceSubmission: getInvoiceSubmission(state, props),
    };
};

const mapDispatchToProps = dispatch => ({
    updateInvoiceSubmission: payload => dispatch({
        type: invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION,
        payload,
    }),
    uploadFile: payload => dispatch(invoiceSubmissionFiles.uploadFile(payload)),
    deleteFile: payload => dispatch(invoiceSubmissionFiles.deleteFile(payload)),
    fetchServiceFixedPricesForSelectedDate: payload => dispatch({
        type: invoiceSubmissionActionTypes.FETCH_SERVICE_FIXED_PRICE_FOR_SELECTED_DATE,
        payload,
    }),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceSubmissionFormView));
